<template>
  <app-modal :show="show_modal_resolve" @close="show_modal_resolve = false">
    <template v-slot:title>
      {{ $t("payout.modal.resolved_title") }}
    </template>
    <template v-slot:body>
      <p class="text-sm text-gray-500">
        {{ $t("payout.modal.resolved_description") }}
      </p>
    </template>
    <template v-slot:action>
      <app-button
        @click="resolvePending"
        :showf70Icon="false"
        :primary="false"
        type="button"
        class="sm:ml-3 sm:w-auto bg-approve text-white hover:bg-green-600"
        :loading="loading"
      >
        {{ $t("payout.modal.mark_as_resolved") }}
      </app-button>
    </template>
  </app-modal>
  <app-modal-payout-detail
    :show="showModalDetail"
    @close="showModalDetail = false"
    :payout="selectPayout"
    processedPaymentLinkRoute="balance.transaction.payment-link"
    @update:payout="selectPayout = $event"
  />
  <div>
    <div class="px-4 border-b pt-10 pb-4">
      <div>
        <p class="font-medium">{{ $t("wallet.payout.payout") }}</p>
      </div>
    </div>
  </div>
  <app-table
    :tabs="tabs"
    :right_tabs="right_tabs"
    :loading="apiPaginationLoading"
    :apiResponse="apiPaginationResponse?.data ?? []"
    @params-changed="paramsChanged"
    @tab-changed="tab_changed"
    :showEmptyButton="false"
    :filters="[
            {
              key: 'filter[amount]',
              type: 'number',
              placeholder: $t('payout.table.account.header_amount'),
            },
            {
              key: 'filter[created_at_between]',
              type: 'date',
              placeholder: $t('wallet.payout.date'),
              value: '',
            },
          ]
    "
    @on-item-click="onItemClick"
  >
    <template v-slot:header>
      <td>
        <b>{{ LabelHeaderDate }}</b>
      </td>
      <td>
        <b>{{ $t("wallet.payout.bank_name") }}</b>
      </td>
      <td>
        <b>{{ $t("wallet.payout.account_no") }}</b>
      </td>
      <td v-if="this.isMasterBusinessRole">
        <b>{{ $t("wallet.payout.business_name") }}</b>
      </td>
      <td>
        <b>{{ $t("wallet.payout.amount") }}</b>
      </td>
      <td v-if="!isStatusPending">
        <b>{{ $t("wallet.payout.status") }}</b>
      </td>
      <td v-if="isStatusPending">
        <b>{{ $t("payout.table.account.header_remark") }}</b>
      </td>
    </template>

    <template v-slot:body="data">
      <td>
        <p>
          {{
            $moment(
              current_tab == PAYOUT_STATUS.REQUESTED
                ? data.model?.created_at
                : data.model?.updated_at
            ).format("DD MMM YYYY")
          }}
        </p>
      </td>
      <td>
        {{ data.model.business.bank?.bank.display_name }}
      </td>
      <td>
        {{ data.model.business.bank?.account_no }}
      </td>
      <td v-if="this.isMasterBusinessRole">
        {{ data.model.business?.name }}
      </td>
      <td>
        {{ $formats.currency(data.model.currency, data.model.amount ?? 0.0) }}
      </td>
      <td v-if="isStatusPending">
        <p v-for="(history, index) in data.model?.histories" :key="index">
          <span v-if="index === data.model.histories.length - 1">
            {{ history.reason ?? "-" }}
          </span>
        </p>
      </td>
      <td v-else>
        <app-badge
          class="capitalize w-24"
          :status="getStatus(data.model?.status)"
        >
          {{ data.model?.status ?? "-" }}
        </app-badge>
      </td>
    </template>

    <template v-slot:footer>
      <tr>
        <td class="font-bold" :colspan="3">
          {{ $t("wallet.transaction.total") }}
        </td>
        <td />
        <td class="font-bold">
          {{
            $formats.currency(
              apiPaginationResponse?.data?.summary?.currency,
              apiPaginationResponse?.data?.summary?.amount ?? 0
            )
          }}
        </td>
        <td>&nbsp;</td>
      </tr>
    </template>
  </app-table>
</template>

<script>
import BUSINESS_STATUS from "@/utils/const/business_status";
import PAYOUT_STATUS from "@/utils/const/payout_status";

export default {
  data() {
    return {
      PAYOUT_STATUS: PAYOUT_STATUS,
      tabs: [],
      right_tabs: [],
      current_tab: null,
      queryParams: "",
      showModalDetail: false,
      showModalWithdraw: false,
      show_modal_resolve: false,
      loading_modal_resolve: false,
      selectPayout: null,
      selectWithdraw: null,
    };
  },
  computed: {
    apiPaginationResponse() {
      return this.$store.getters["walletStore/apiPaginationResponse"];
    },

    business() {
      return this.$store.getters["businessStore/business"];
    },

    banks() {
      return this.$store.getters["referenceStore/banks"];
    },

    loading() {
      return this.$store.getters["walletStore/loading"];
    },

    apiPaginationLoading() {
      return this.$store.getters["walletStore/apiPaginationLoading"];
    },

    isMasterBusinessRole() {
      return this.$store.getters["authStore/isMasterBusinessRole"];
    },

    all_business() {
      return this.$store.getters["masterBusinessStore/all_business"];
    },
    isStatusPending() {
      return [PAYOUT_STATUS.PENDING].includes(this.current_tab);
    },
    isStatusProcessing() {
      return [PAYOUT_STATUS.PROCESSING].includes(this.current_tab);
    },
    isStatusApproved() {
      return [PAYOUT_STATUS.APPROVED].includes(this.current_tab);
    },
    isStatusReturn() {
      return [PAYOUT_STATUS.RETURNED].includes(this.current_tab);
    },
    isStatusRejected() {
      return [PAYOUT_STATUS.REJECTED].includes(this.current_tab);
    },
    LabelHeaderDate() {
      switch (this.current_tab) {
        case PAYOUT_STATUS.PROCESSING:
          return this.$t("payout.table.account.header_date_processed");
        case PAYOUT_STATUS.APPROVED:
          return this.$t("payout.table.account.header_date_success");
        case PAYOUT_STATUS.RETURNED:
          return this.$t("payout.table.account.header_date_returned");
        case PAYOUT_STATUS.PENDING:
          return this.$t("payout.table.account.header_date_pending");
        default:
          return this.$t("payout.table.account.header_date_requested");
      }
    },
  },

  mounted() {
    this.fetchListPayout();

    this.$store.dispatch("referenceStore/getBanks", 0);

    this.tabs = [
      {
        key: PAYOUT_STATUS.REQUESTED,
        title: this.$t("payout.table.account.tab_request"),
      },
      {
        key: PAYOUT_STATUS.PROCESSING,
        title: this.$t("payout.table.account.tab_settlement"),
      },
      {
        key: PAYOUT_STATUS.PENDING,
        title: this.$t("payout.table.account.tab_pending"),
      },
    ];

    this.right_tabs = [
      {
        key: PAYOUT_STATUS.RETURNED,
        title: this.$t("payout.table.account.tab_returned"),
      },
      {
        key: PAYOUT_STATUS.APPROVED,
        title: this.$t("payout.table.account.tab_approved"),
      },
    ];

    this.$store.dispatch("masterBusinessStore/getMasterBusiness", {
      business_status_id: BUSINESS_STATUS.APPROVED,
    });
  },
  methods: {
    getStatus(status) {
      switch (status) {
        case PAYOUT_STATUS.APPROVED:
          return "success";
        case PAYOUT_STATUS.PROCESSING:
        case PAYOUT_STATUS.REQUESTED:
        case PAYOUT_STATUS.PENDING:
          return "light";
        case PAYOUT_STATUS.REJECTED:
          return "error";
        default:
          return "light";
      }
    },

    paramsChanged(filters, queryParams) {
      this.queryParams = queryParams;
      this.fetchListPayout();
    },

    fetchListPayout() {
      this.getTabStatusTab();
      this.$store.dispatch(
        "walletStore/retrieveWalletPayouts",
        this.queryParams
      );
    },

    refresh() {
      window.location.href = this.$route.path;
    },

    tab_changed(tab_name) {
      this.current_tab = tab_name;
    },

    getTabStatusTab() {
      let currentTab = this.current_tab ?? this.$route.query.tab;

      if (currentTab == null) {
        currentTab = PAYOUT_STATUS.REQUESTED;
      }

      this.current_tab = currentTab;

      this.queryParams =
        this.queryParams == ""
          ? "?filter[status]=" + this.current_tab
          : this.queryParams + "&filter[status]=" + this.current_tab;
    },

    onItemClick(data) {
      this.showModalDetail = !this.showModalDetail;
      this.selectPayout = data;
    },
    showModalResolve(data) {
      if (data.resolved_at) {
        return;
      }
      this.show_modal_resolve = !this.show_modal_resolve;
      this.selectPayout = data;
    },
    onClickWithdraw() {
      this.showModalWithdraw = !this.showModalWithdraw;
    },
    async resolvePending() {
      let response = await this.$store.dispatch(
        "walletStore/updatePayoutResolve",
        {
          payout_id: this.selectPayout?._id,
        }
      );

      if (this.$lodash.isEmpty(response.errors)) {
        this.show_modal_resolve = false;
        this.fetchListPayout();
      }
    },
  },
};
</script>
